<template>
  <v-sheet
    class="video-block"
    :height="content.height"
    :width="content.width"
    :max-height="content.maxHeight"
    :max-width="content.maxWidth"
    :class="{
      'video-block--with-height': !!content.height,
      'video-block--with-overlay': content.overlay || content.link,
    }"
    :style="{
      '--height': height,
    }"
  >
    <div class="video-block__content">
      <video
        ref="video"
        v-bind="videoProps"
        class="video-block__element"
        @playing="onPlaying"
        @pause="onPause"
        @ended="onEnded"
      >
        <template v-if="!isString(src)">
          <source
            v-for="(s, format) in src"
            :key="format"
            :src="s"
            :type="`video/${format}`"
          />
        </template>
      </video>
      <OverlayBlock
        v-if="content.overlay || content.link"
        :content="{ ...content.overlay, link: content.link }"
      />
      <div v-if="content.controls !== false" class="button-container">
        <div v-ripple class="control-btn" @click="togglePlay">
          <v-icon :icon="playing ? 'pause' : 'play_arrow'" size="x-large" />
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import defu from "defu";
import { isString, pick } from "lodash";
import { defineComponent, type PropType } from "vue";
import type { VideoContent } from ".";
import { useResizable } from "./utils";

const defaults: Omit<VideoContent, "src"> = {
  autoplay: true,
  controls: false,
  muted: true,
  playbackRate: 1,
  autobuffer: true,
  playsinline: true,
};

export default defineComponent({
  name: "VideoBlock",
  props: {
    content: { type: Object as PropType<VideoContent>, required: true },
  },
  setup(props) {
    const playing = ref(false);
    const video = ref<HTMLVideoElement>();

    const togglePlay = () => {
      if (video.value) {
        if (playing.value) {
          video.value.pause();
        } else {
          video.value.play();
        }
      }
    };

    const height = computed(() => convertToUnit(props.content.height));

    const onPlaying = () => (playing.value = true);
    const onPause = () => (playing.value = false);
    const onEnded = () => (playing.value = false);

    const src = useResizable(props.content.src);

    return {
      playing,
      togglePlay,
      video,
      onPlaying,
      onPause,
      onEnded,
      height,
      src,
    };
  },
  computed: {
    videoProps() {
      const props: any = defu(
        pick(this.content, [
          "autoplay",
          "controls",
          "muted",
          "playbackRate",
          "autobuffer",
          "playsinline",
          "loop",
          "poster",
        ]),
        defaults
      );

      if (isString(this.src)) {
        props.src = this.src;
      }

      return props;
    },
  },
  data: () => ({
    isString,
  }),
});
</script>

<style lang="scss" scoped>
@use "/assets/styles/breakpoints";

.video-block {
  position: relative;

  &__content {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0;
    overflow: hidden;
    video[poster] {
      object-fit: cover;
    }
  }

  &--with-height {
    height: var(--height);
  }

  &--with-height &__element {
    // object-fit: fill;
    width: 100%;
  }

  &--with-height &__element- {
    // Great css solution
    // https://stackoverflow.com/a/29997746/8014660

    // Base
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */

    // For centering
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */
  }

  &--with-overlay .control-btn {
    background: rgba(0, 0, 0, 0.36);
  }

  .button-container {
    position: absolute;
    bottom: 10px;
    right: 10px;

    @include breakpoints.up(md) {
      bottom: 30px;
      right: 30px;
      .control-btn {
        height: 40px;
        width: 40px;
      }
    }

    .control-btn {
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.143);
      height: 30px;
      width: 30px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      color: white;
      i.v-icon {
        font-size: 20px;
      }
    }
  }

  // .overlay-content__scrim {
  //   background: rgba(0, 0, 0, 0.528);
  // }
}
</style>
